$(document).ready(function () {
    window.lastScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
    window.lastScrollEvent = null;

    window.addEventListener(
        'scroll',
        function handleScroll() {
            const scrollTopPosition =
                window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTopPosition > lastScrollTop) {
                if (lastScrollEvent != 'scrollDownStart') {
                    lastScrollEvent = 'scrollDownStart';
                    window.dispatchEvent(new Event('scrollDownStart'));
                }
            } else if (scrollTopPosition < lastScrollTop) {
                if (lastScrollEvent != 'scrollUpStart') {
                    lastScrollEvent = 'scrollUpStart';
                    window.dispatchEvent(new Event('scrollUpStart'));
                }
            }
            lastScrollTop =
                scrollTopPosition <= 0 ? 0 : scrollTopPosition;
        },
        { passive: true }
    );

    window.addEventListener('scrollDownStart', function (ev) {
        //console.log("Scroll down started");
        document.querySelector('header').classList.add('scroll-down');
    }, { passive: true });
    window.addEventListener('scrollUpStart', function (ev) {
        //console.log("Scroll up started");
        document.querySelector('header').classList.remove('scroll-down');
    }, { passive: true });

    $("#hamburgerMenu").on('click', ev => {
        document.querySelector('body').classList.toggle('mobileMenuActive');
        if ($("body").hasClass('mobileMenuActive')) {
            $("#mobileMenu a").removeAttr('tabindex');
        } else {
            $("#mobileMenu a").attr('tabindex', '-1');
        }
        $("#mobileMenu").attr('aria-hidden', !$("body").hasClass('mobileMenuActive'));
        return (false);
    });

    document.getElementById('closeMobileMenu').addEventListener('click', ev => {
        $("#hamburgerMenu").trigger("click");
        //document.querySelector('body').classList.toggle('mobileMenuActive');
        //$("#mobileMenu").attr('aria-hidden', !$("body").hasClass('mobileMenuActive'));
    });

    document.querySelectorAll('.home__qa dt').forEach(function (elem) {
        elem.addEventListener('click', function (ev) {
            ev.preventDefault();
            elem.classList.toggle('active');
        });
        elem.addEventListener('keydown', function (ev) {
            if (ev.key === 'Enter' || ev.key === ' ') { // Checks for Enter or Space
                ev.preventDefault(); // Prevent the default action to avoid scrolling when Space is pressed
                elem.classList.toggle('active');
            }
        });
    });

    jQuery("#itemTabsFrame ul#tabControls li").click(function () {
        switch (jQuery(this).index()) {
            case 0:
                break;
            case 1:
                jQuery("html, body").animate(
                    { scrollTop: jQuery("#ytimesQnA").offset().top },
                    "slow"
                );
                break;
            case 2:
                jQuery("html, body").animate(
                    { scrollTop: jQuery("#reviews").offset().top },
                    "slow"
                );
                break;
        }
    });
    if (typeof (document.body.dataset.topSection) != 'undefined') {
        const topSection = document.body.dataset.topSection;
        $(".desktop-menu a").removeClass('active');
        $(`.desktop-menu a[href='${topSection}.html']`).addClass('active');
    }

    $(".itemOption input[type='radio']")
        .on("change", function () {
            let totalPrice = 0;
            $(".itemOption input:checked").each(function () {
                let optValue = $(this).val();
                let price = optValue.match(/\(\+(\d+(\.\d+)?)\)/);
                let floatPrice = 0;
                if (price) {
                    floatPrice = parseFloat(price[1]);
                }
                let formattedPrice = floatPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: floatPrice % 1 === 0 ? 0 : 2,
                });
                totalPrice += floatPrice;
            });
            let formattedPrice = totalPrice.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
            if (totalPrice == 0) {
                formattedPrice = "Select Option:";
                $(".addtocartImg").prop("disabled", true)
            } else {
                $(".addtocartImg").removeProp("disabled");
            }
            $(".itemOrderForm .priceBold").html(
                "<b>" + formattedPrice + "</b>"
            );
        })
        .trigger("change");

    $(".home__qa").before(randomTestimonials());

    initSliders();
});

function yt_backdrop(onoff) {
    if (!document.getElementById('ytBackDrop')) {
        let bd = document.createElement('div');
        bd.id = 'ytBackDrop';
        document.querySelector('body').appendChild(bd);
    }
    if (onoff) {
        if (!document.getElementById('ytBackDrop').classList.contains('active')) {
            document.getElementById('ytBackDrop').classList.add('active');
        }
    } else {
        document.getElementById('ytBackDrop').classList.remove('active');
    }
}

function initSliders() {
    try {
        $(".showcaseImages").slick({
            arrows: true,
            autoplay: false,
            autoplaySpeed: 7000,
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: false,
            responsive: [
                {
                    breakpoint: 760,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

        $(".projects").slick({
            arrows: true,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

        $(".prodImages").slick({
            arrows: true,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
        });
    } catch (e) {
        console.error('initSliders error', e);
    }
}

function randomTestimonials() {
    let filteredTestimonials = testimonials.filter(testimonial => testimonial.length <= 600);
    let theseTestimonials = filteredTestimonials.sort(() => 0.5 - Math.random()).slice(0, 3);
    let testimonialsDiv = $("<div class='testimonials'></div>");
    testimonialsDiv.append("<h2>What our Customers Say</h2>");
    let testimonialsContainer = $("<div class='testimonials-container'></div>");
    theseTestimonials.forEach(function (testimonial) {
        let testimonialDiv = $("<div class='testimonial'></div>");
        testimonialDiv.append(testimonial + `<img alt="5 stars rating" src="https://ytimes.net/ytimes/yreviews/ssl/images/5-stars.png" width="120" height="24" loading=lazy alt="5 stars rating" />`);
        testimonialsContainer.append(testimonialDiv);
    });
    testimonialsDiv.append(testimonialsContainer);
    return testimonialsDiv;
}
